import React from "react"
import  "../styles/global.scss"
import { Container, Col, Row } from 'reactstrap';
import Layout from "../layouts/index"
import { withPrefix } from "gatsby"
import { IoIosAnalytics } from "react-icons/io";
import { Helmet } from 'react-helmet';

const ServicePage  = () => (
	<Layout>
		<Helmet>
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
          <meta name="viewport" content="width=device-width; initial-scale=1.0; maximum-scale=1.0; user-scalable=0;" />
          <title>Interaid Pharmacy | Services we offer</title>
          <meta name="title" content="Interaid Pharmacy | Services we offer" />
			<meta name="description" content="We’ve got your medicine covered, but there is always more to offer at Interaid Pharmacy." />
          <link rel="canonical" href="www.interaidpharmacy.com.my/service" />

        </Helmet>

		<Container fluid="true" className="page-title-block service-page-title-block">
	        <Row>
	  			<span className="text-center font-dark text-bold-500 text-white">Service</span>
	 		</Row>
	    </Container>
		
		<Container fluid="true" className="service-page page-block text-center">
  
			<IoIosAnalytics size="2em" color="#6d886f"/>
	     	<br/><br/>
			<p className="page-summary font-dark text-bold-500">We’ve got your medicine covered, but there is always more to offer at Interaid Pharmacy. </p>
			<hr className="title-divider"/>
	     	<br/>

			
			<Row className="block-center" style={{marginTop:'50px'}}>
				<Col md="3">
					<div className="icon">
	    				<img src={withPrefix('/assets/img/prescription-service.png')} alt="Logo" width="300px" height="300px" class="img-fluid"/>

					</div>
				</Col>
				<Col md="9" className="service-desc-right">
 					<span className="thin-p service-desc-title-right text-bold-700">Prescription refilling</span><br/><br/>
					You may present your prescription or medication samples to us and have your medication prepared with a complete prescription translation on how to take your medication accordingly. We stand ready to serve you with every prescription need and source for medication that requires special order at no extra charges
					</Col>
			</Row>
			
				<Row className="block-center mobile">
				<Col md="3">
					<div className="icon">
	    				<img src={withPrefix('/assets/img/healthcheck-service.png')} alt="Logo" width="300px" height="300px" class="img-fluid"/>

					</div>
				</Col>
				<Col md="9" className="service-desc-right">
 					<span className="thin-p service-desc-title-right text-bold-700">Basic Healthcare Monitoring </span><br/>
				You may drop by to our outlet and have some healthchecks, including blood pressure testing, blood sugar monitoring, cholesterol lipid profile analysis and body fat composition analysis. You will receive a free consultation upon receiving your healthchecks result. Let us help you to track important numbers that impact your overall health.
					</Col>
	

			</Row>
		
			<Row className="block-center desktop">
				
				<Col md="9" className="service-desc-left">
 					<span className="thin-p service-desc-title-right text-bold-700">Basic Healthcare Monitoring </span><br/><br/>
				You may drop by to our outlet for basic healthchecks, including blood pressure testing, blood sugar monitoring, cholesterol lipid profile analysis and body fat composition analysis. You will receive a free consultation upon receiving your healthchecks result. Let us help you to track important numbers that impact your overall health.
				</Col>

				<Col md="3" >
					<div className="icon">
	    				<img src={withPrefix('/assets/img/healthcheck-service.png')} alt="Logo" width="300px" height="300px" class="img-fluid"/>
						
					</div>
				</Col>

			</Row>

			
			<Row className="block-center">
				<Col md="3">
					<div className="icon">
	    				<img src={withPrefix('/assets/img/smoking-service.png')} alt="Logo" width="300px" height="300px" class="img-fluid"/>
					</div>
				</Col>
				<Col md="9" className="service-desc-right">
 					<span className="thin-p service-desc-title-right text-bold-700">Free Smoking Cessation</span><br/><br/>
					We provide free stop smoking intervention via behavioural support and nicotine replacement therapy (NRT) if needed. We are open to discuss about any stop smoking aids that you have used before, including personally purchased nicotine containing products and provide further assist on how you effectively use them.
				</Col>
			</Row>
			
			<Row className="block-center mobile">
				<Col md="3">
					<div className="icon">
	    				<img src={withPrefix('/assets/img/wound-care-service.png')} alt="Logo" width="300px" height="300px" class="img-fluid"/>

					</div>
				</Col>
				<Col md="9" className="service-desc-right">
 					<span className="thin-p service-desc-title-right text-bold-700">Wound Care</span><br/>
					Interaid Pharmacy has a large variety of specialty bandages, closures, dressings, and topical wound care that your doctor recommends.
					We also provide free wound cleansing and wound dressing demonstration on your actual wound with the wound care supplies you purchased. Forget about buying irrelevant supplies or supplies that you have no idea how it works from now on.
				</Col>
	

			</Row>

			<Row className="block-center desktop">
				
				<Col md="9" className="service-desc-left">
 					<span className="thin-p service-desc-title-right text-bold-700">Wound Care</span><br/><br/>
					Interaid Pharmacy has a large variety of specialty bandages, closures, dressings, and topical wound care that your doctor recommends.
					We also provide free wound cleansing and wound dressing demonstration on your actual wound with the wound care supplies you purchased. Forget about buying irrelevant supplies or supplies that you have no idea how it works from now on.
				</Col>

				<Col md="3">
					<div className="icon">
	    				<img src={withPrefix('/assets/img/wound-care-service.png')} alt="Logo" width="300px" height="300px" class="img-fluid"/>
					</div>
				</Col>

			</Row>

			<Row className="block-center">
				<Col md="3">
					<div className="icon">
	    			<img src={withPrefix('/assets/img/bill-service.png')} alt="Logo" width="300px" height="300px" class="img-fluid"/>
					</div>
				</Col>
				<Col md="9" className="service-desc-right">
 					<span className="thin-p service-desc-title-right text-bold-700">E-pay Service</span><br/><br/>
					We provide credit top up and utilities bill payment service free of charge. However, we accept cash payment only for this service. Please get your bills or the account numbers prepared prior to coming to our outlet.
				</Col>
			</Row>

		
			<Row className="block-center mobile">
				<Col md="3">
					<div className="icon">
	    				<img src={withPrefix('/assets/img/drug-container-service.png')} alt="Logo" width="300px" height="300px" class="img-fluid"/>

					</div>
				</Col>
				<Col md="9" className="service-desc-right">
 					<span className="thin-p service-desc-title-right text-bold-700">Drug Container Waste Recycle</span><br/>
					We collect empty medicine box, medicine or supplement bottles, empty insulin pen and empty inhaler for recycle purpose. Insulin pens and inhaler provider will entitle a certain amount of cash rebate during the same medication refill. Let’s work together to make our environment greener!

					</Col>
	

			</Row>

			<Row className="block-center desktop">
				
				<Col md="9" className="service-desc-left">
 					<span className="thin-p service-desc-title-right text-bold-700">Drug Container Waste Recycle</span><br/><br/>
					We collect empty medicine box, medicine or supplement bottles, empty insulin pen and empty inhaler for recycle purpose. Insulin pens and inhaler provider will entitle a certain amount of cash rebate during the same medication refill. Let’s work together to make our environment greener. 
					
				</Col>

				<Col md="3">
				<div className="icon">
	    				<img src={withPrefix('/assets/img/drug-container-service.png')} alt="Logo" width="300px" height="300px" class="img-fluid"/>
					</div>
				</Col>

			</Row>		
	

		</Container>
	</Layout>
 )

export default ServicePage

		// <img src={withPrefix('/assets/svg/hot-air-balloon.svg')} width="40px" class="img-fluid text-grey"/>